import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H1Pro2023 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h1 className="pro">Pro Championship</h1>
			<p><small>Dropped scores (DS) will appear after 6 rounds have been completed</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
							<th>R1 <span>Nurburgring 1</span></th>
							<th>R2 <span>Nurburgring 2</span></th>
							<th>R3 <span>Kyalami 1</span></th>
							<th>R4 <span>Kyalami 2</span></th>
							<th>R5 <span>Imola 1</span></th>
							<th>R6 <span>Imola 2</span></th>
							<th>R7 <span>Bahrain 1</span></th>
							<th>R8 <span>Bahrain 2</span></th>
							<th>R9 <span>Jeddah 1</span></th>
							<th>R10 <span>Jeddah 2</span></th>
							<th>R11 <span>Albert Park 1</span></th>
							<th>R12 <span>Albert Park 2</span></th>
							<th>R13 <span>Mugello 1</span></th>
							<th>R14 <span>Mugello 2</span></th>
							<th>R15 <span>Baku 1</span></th>
							<th>R16 <span>Baku 2</span></th>
							<th>R17 <span>Miami 1</span></th>
							<th>R18 <span>Miami 2</span></th>
							<th>R19 <span>Monaco 1</span></th>
							<th>R20 <span>Monaco 2</span></th>
							<th>R21 <span>Barcelona 1</span></th>
							<th>R22 <span>Barcelona 2</span></th>
							<th>R23 <span>Canada 1</span></th>
							<th>R24 <span>Canada 2</span></th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
					{props.db.h12023pro.map((data) => (
								<tr className={data.class} key={data.driver}>
									<td>{data.pos}</td>
									<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
									<td>{data.r1}</td>
									<td>{data.r2}</td>
									<td>{data.r3}</td>
									<td>{data.r4}</td>
									<td>{data.r5}</td>
									<td>{data.r6}</td>
									<td>{data.r7}</td>
									<td>{data.r8}</td>
									<td>{data.r9}</td>
									<td>{data.r10}</td>
									<td>{data.r11}</td>
									<td>{data.r12}</td>
									<td>{data.r13}</td>
									<td>{data.r14}</td>
									<td>{data.r15}</td>
									<td>{data.r16}</td>
									<td>{data.r17}</td>
									<td>{data.r18}</td>
									<td>{data.r19}</td>
									<td>{data.r20}</td>
									<td>{data.r21}</td>
									<td>{data.r22}</td>
									<td>{data.r23}</td>
									<td>{data.r24}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H1Pro2023.propTypes = {
	db: PropTypes.shape({
		h12023pro: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h12023pro')(H1Pro2023);
