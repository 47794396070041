import React from "react";
import PropTypes from "prop-types";
import { withGoogleSheets } from "react-db-google-sheets";

const H22023 = (props) => (
  <div className="Overall">
    <div className="flex content-center">
      <div className="flex-grow self-center px-4">
        <h1 className="pro">Overall Championship</h1>
        <p>
          <small>
            Dropped scores (DS) will appear after 6 rounds have been completed
          </small>
        </p>
      </div>
    </div>

    <div className="flex">
      <div className="col px-4">
        <div className="overallTable table-responsive rounds">
          <table cellPadding="0" cellSpacing="0" border="0" className="table">
            <thead>
              <tr>
                <th>Pos</th>
                <th className="grow">Driver</th>
                <th className="overall">Overall</th>
                <th className="ds">DS</th>
                <th>
                  R1 <span>Zandvoort 1</span>
                </th>
                <th>
                  R2 <span>Zandvoort 2</span>
                </th>
                <th>
                  R3 <span>Monza 1</span>
                </th>
                <th>
                  R4 <span>Monza 2</span>
                </th>
                <th>
                  R5 <span>Singapore 1</span>
                </th>
                <th>
                  R6 <span>Singapore 2</span>
                </th>
                <th>
                  R7 <span>Suzuka 1</span>
                </th>
                <th>
                  R8 <span>Suzuka 2</span>
                </th>
                <th>
                  R9 <span>Qatar 1</span>
                </th>
                <th>
                  R10 <span>Qatar 2</span>
                </th>
                <th>
                  R11 <span>Mexico 1</span>
                </th>
                <th>
                  R12 <span>Mexico 2</span>
                </th>
                <th>
                  R13 <span>Interlagos 1</span>
                </th>
                <th>
                  R14 <span>Interlagos 2</span>
                </th>
                <th>
                  R15 <span>Yas Marina 1</span>
                </th>
                <th>
                  R16 <span>Yas Marina 2</span>
                </th>
                <th>
                  R17 <span>Las Vegas 1</span>
                </th>
                <th>
                  R18 <span>Las Vegas 2</span>
                </th>
                <th className="overall">Overall</th>
                <th className="ds">DS</th>
              </tr>
            </thead>
            <tbody>
              {props.db.h22023.map((data) => (
                <tr className={data.class} key={data.driver}>
                  <td>{data.pos}</td>
                  <td className="grow">
                    <span className="number" style={{ color: data.colour }}>
                      {data.number}
                    </span>{" "}
                    {data.driver}
                  </td>
                  <td>
                    <span>{data.overall}</span>
                  </td>
                  <td>
                    <span>{data.dropped}</span>
                  </td>
                  <td>{data.r1}</td>
                  <td>{data.r2}</td>
                  <td>{data.r3}</td>
                  <td>{data.r4}</td>
                  <td>{data.r5}</td>
                  <td>{data.r6}</td>
                  <td>{data.r7}</td>
                  <td>{data.r8}</td>
                  <td>{data.r9}</td>
                  <td>{data.r10}</td>
                  <td>{data.r11}</td>
                  <td>{data.r12}</td>
                  <td>{data.r13}</td>
                  <td>{data.r14}</td>
                  <td>{data.r15}</td>
                  <td>{data.r16}</td>
                  <td>{data.r17}</td>
                  <td>{data.r18}</td>
                  <td>
                    <span>{data.overall}</span>
                  </td>
                  <td>
                    <span>{data.dropped}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

H22023.propTypes = {
  db: PropTypes.shape({
    h22023: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default withGoogleSheets("h22023")(H22023);
