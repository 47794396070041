import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H1Rounds2021 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="pro">Overall Championship</h2>
			<p><small>Ordered by dropped score (DS)</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th>R1 <span>Jerez 1</span></th>
							<th>R2 <span>Jerez 2</span></th>
							<th>R3 <span>Spa 1</span></th>
							<th>R4 <span>Spa 2</span></th>
							<th>R5 <span>Kyalami 1</span></th>
							<th>R6 <span>Kyalami 2</span></th>
							<th>R7 <span>Shanghai 1</span></th>
							<th>R8 <span>Shanghai 2</span></th>
							<th>R9 <span>Albert Park 1</span></th>
							<th>R10 <span>Albert Park 2</span></th>
							<th>R11 <span>Bahrain 1</span></th>
							<th>R12 <span>Bahrain 2</span></th>
							<th>R13 <span>Imola 1</span></th>
							<th>R14 <span>Imola 2</span></th>
							<th>R15 <span>Portimao 1</span></th>
							<th>R16 <span>Portimao 2</span></th>
							<th>R17 <span>Barcelona 1</span></th>
							<th>R18 <span>Monaco 1</span></th>
							<th>R19 <span>Monaco 2</span></th>
							<th>R20 <span>Baku 1</span></th>
							<th>R21 <span>Baku 2</span></th>
							<th>R22 <span>Montreal 1</span></th>
							<th>R23 <span>Montreal 2</span></th>
							<th>R24 <span>Montreal 3</span></th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
						{props.db.h12021overall.map((data) => (
							<tr className={data.class} key={data.driver}>
								<td>{data.pos}</td>
								<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
								<td>{data.jerez1}</td>
								<td>{data.jerez2}</td>
								<td>{data.spa1}</td>
								<td>{data.spa2}</td>
								<td>{data.kyalami1}</td>
								<td>{data.kyalami2}</td>
								<td>{data.shanghai1}</td>
								<td>{data.shanghai2}</td>
								<td>{data.albert1}</td>
								<td>{data.albert2}</td>
								<td>{data.bahrain1}</td>
								<td>{data.bahrain2}</td>
								<td>{data.imola1}</td>
								<td>{data.imola2}</td>
								<td>{data.portimao1}</td>
								<td>{data.portimao2}</td>
								<td>{data.barcelona1}</td>
								<td>{data.monaco1}</td>
								<td>{data.monaco2}</td>
								<td>{data.baku1}</td>
								<td>{data.baku2}</td>
								<td>{data.montreal1}</td>
								<td>{data.montreal2}</td>
								<td>{data.montreal3}</td>
								<td><span>{data.overall}</span></td>
								<td><span>{data.dropped}</span></td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H1Rounds2021.propTypes = {
	db: PropTypes.shape({
		h12021overall: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h12021overall')(H1Rounds2021);
