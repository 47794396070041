import React from "react";
import PropTypes from "prop-types";
import { withGoogleSheets } from "react-db-google-sheets";

const H12024 = (props) => (
  <div className="Overall">
    <div className="flex content-center">
      <div className="flex-grow self-center px-4">
        <h1 className="pro">Overall Championship</h1>
        <p>
          <small>
            Dropped scores (DS) will appear after 6 rounds have been completed
          </small>
        </p>
      </div>
    </div>

    <div className="flex">
      <div className="col px-4">
        <div className="overallTable table-responsive rounds">
          <table cellPadding="0" cellSpacing="0" border="0" className="table">
            <thead>
              <tr>
                <th>Pos</th>
                <th className="grow">Driver</th>
                <th className="overall">Overall</th>
                <th className="ds">DS</th>
                <th>
                  R1 <span>Barcelona 1</span>
                </th>
                <th>
                  R2 <span>Barcelona 2</span>
                </th>
                <th>
                  R3 <span>Istanbul 1</span>
                </th>
                <th>
                  R4 <span>Istanbul 2</span>
                </th>
                <th>
                  R5 <span>Silverstone 1</span>
                </th>
                <th>
                  R6 <span>Silverstone 2</span>
                </th>
                <th>
                  R7 <span>Imola 1</span>
                </th>
                <th>
                  R8 <span>Imola 2</span>
                </th>
                <th>
                  R9 <span>Bahrain 1</span>
                </th>
                <th>
                  R10 <span>Bahrain 2</span>
                </th>
                <th>
                  R11 <span>Jeddah 1</span>
                </th>
                <th>
                  R12 <span>Jeddah 2</span>
                </th>
                <th>
                  R13 <span>Melbourne 1</span>
                </th>
                <th>
                  R14 <span>Melbourne 2</span>
                </th>
                <th>
                  R15 <span>Suzuka 1</span>
                </th>
                <th>
                  R16 <span>Suzuka 2</span>
                </th>
                <th>
                  R17 <span>Shanghai 1</span>
                </th>
                <th>
                  R18 <span>Shanghai 2</span>
                </th>
                <th>
                  R19 <span>Miami 1</span>
                </th>
                <th>
                  R20 <span>Miami 2</span>
                </th>
                <th>
                  R21 <span>Monaco 1</span>
                </th>
                <th>
                  R22 <span>Monaco 2</span>
                </th>
                <th>
                  R23 <span>Montreal 1</span>
                </th>
                <th>
                  R24 <span>Montreal 2</span>
                </th>
                <th className="overall">Overall</th>
                <th className="ds">DS</th>
              </tr>
            </thead>
            <tbody>
              {props.db.h12024.map((data) => (
                <tr className={data.class} key={data.driver}>
                  <td>{data.pos}</td>
                  <td className="grow">
                    {data.driver}
                  </td>
                  <td>
                    <span>{data.overall}</span>
                  </td>
                  <td>
                    <span>{data.dropped}</span>
                  </td>
                  <td>{data.r1}</td>
                  <td>{data.r2}</td>
                  <td>{data.r3}</td>
                  <td>{data.r4}</td>
                  <td>{data.r5}</td>
                  <td>{data.r6}</td>
                  <td>{data.r7}</td>
                  <td>{data.r8}</td>
                  <td>{data.r9}</td>
                  <td>{data.r10}</td>
                  <td>{data.r11}</td>
                  <td>{data.r12}</td>
                  <td>{data.r13}</td>
                  <td>{data.r14}</td>
                  <td>{data.r15}</td>
                  <td>{data.r16}</td>
                  <td>{data.r17}</td>
                  <td>{data.r18}</td>
                  <td>{data.r19}</td>
                  <td>{data.r20}</td>
                  <td>{data.r21}</td>
                  <td>{data.r22}</td>
                  <td>{data.r23}</td>
                  <td>{data.r24}</td>
                  <td>
                    <span>{data.overall}</span>
                  </td>
                  <td>
                    <span>{data.dropped}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

H12024.propTypes = {
  db: PropTypes.shape({
    h12024: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default withGoogleSheets("h12024")(H12024);
