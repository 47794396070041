import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleSheets } from 'react-db-google-sheets';

const H2Am2022 = (props) => (
	<div className="Overall">
	<div className="flex content-center">
		<div className="flex-grow self-center px-4">
			<h2 className="am">AM Championship</h2>
			<p><small>Dropped scores (DS) will appear after 6 rounds have been completed</small></p>
		</div>
	</div>

	<div className="flex">
		<div className="col px-4">
			<div className="overallTable table-responsive rounds">
				<table cellPadding="0" cellSpacing="0" border="0" className="table">
					<thead>
						<tr>
							<th>Pos</th>
							<th className="grow">Driver</th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
							<th>R1 <span>Red Bull Ring 1</span></th>
							<th>R2 <span>Red Bull Ring 2</span></th>
							<th>R3 <span>Paul Ricard 1</span></th>
							<th>R4 <span>Paul Ricard 2</span></th>
							<th>R5 <span>Hungary 1</span></th>
							<th>R6 <span>Hungary 2</span></th>
							<th>R7 <span>Silverstone 1</span></th>
							<th>R8 <span>Silverstone 2</span></th>
							<th>R9 <span>Spa 1</span></th>
							<th>R10 <span>Spa 2</span></th>
							<th>R11 <span>Monza 1</span></th>
							<th>R12 <span>Monza 2</span></th>
							<th>R13 <span>Singapore 1</span></th>
							<th>R14 <span>Singapore 2</span></th>
							<th>R15 <span>Austin 1</span></th>
							<th>R16 <span>Austin 2</span></th>
							<th>R17 <span>Mexico 1</span></th>
							<th>R18 <span>Mexico 2</span></th>
							<th>R19 <span>Brazil 1</span></th>
							<th>R20 <span>Brazil 2</span></th>
							<th>R21 <span>Abu Dhabi 1</span></th>
							<th>R22 <span>Abu Dhabi 2</span></th>
							<th>R23 <span>Suzuka 1</span></th>
							<th>R24 <span>Suzuka 2</span></th>
							<th className="overall">Overall</th>
							<th className="ds">DS</th>
						</tr>
					</thead>
					<tbody>
					{props.db.h22022am.map((data) => (
								<tr className={data.class} key={data.driver}>
									<td>{data.pos}</td>
									<td className="grow"><span className="number" style={{color:data.colour}}>{data.number}</span> {data.driver}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
									<td>{data.redBullRing1}</td>
									<td>{data.redBullRing2}</td>
									<td>{data.paulRicard1}</td>
									<td>{data.paulRicard2}</td>
									<td>{data.hungary1}</td>
									<td>{data.hungary2}</td>
									<td>{data.silverstone1}</td>
									<td>{data.silverstone2}</td>
									<td>{data.spa1}</td>
									<td>{data.spa2}</td>
									<td>{data.monza1}</td>
									<td>{data.monza2}</td>
									<td>{data.singapore1}</td>
									<td>{data.singapore2}</td>
									<td>{data.austin1}</td>
									<td>{data.austin2}</td>
									<td>{data.mexico1}</td>
									<td>{data.mexico2}</td>
									<td>{data.brazil1}</td>
									<td>{data.brazil2}</td>
									<td>{data.abuDhabi1}</td>
									<td>{data.abuDhabi2}</td>
									<td>{data.suzuka1}</td>
									<td>{data.suzuka2}</td>
									<td><span>{data.overall}</span></td>
									<td><span>{data.dropped}</span></td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
);

H2Am2022.propTypes = {
	db: PropTypes.shape({
		h22022am: PropTypes.arrayOf(PropTypes.object),
	}),
};

export default withGoogleSheets('h22022am')(H2Am2022);
